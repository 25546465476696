.sidebarBox{
  width: 225px;
  height: calc(100vh - 20px);
  background-color: #212121;
  position: sticky;
  top: 0;
  z-index: 1500;
  caret-color: transparent;
  transition: all 300ms ease;
}


.sidebarLinksBox{
  padding-top: 5px;
}

.sidebarPageLinks{
  color: white;
  font-size: 20px;
  padding-top: 10px;
  transition: all 100ms ease;
}

.sidebarPageLinks:hover{
  color: #3aa3f5;
  cursor: pointer;
}

.titleHeaderBox{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  color: #3aa3f5;
  caret-color: transparent;
  line-break:unset;
}

.titleHeaderIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 1.4;
  padding-right: 6px;
}

.titleHeader{
  font-weight: 500;
  font-size: 26px;
}