.dashboardInfoBox {
  height: 350px;
  border: 1px solid #aaaaaa;
  margin: 10px;
  background-color: #212121;
  max-width: 600px;
  border-radius: 5px;
}

.dashboardInfoHeaderBox {
  color: #eeeeee;
  padding: 2px 5px 5px 5px;
  border-bottom: 1px solid #aaaaaa;
}

.dashboardInfoBodyBox {
  color: #eeeeee;
  font-size: 11px;
  overflow: auto;
  height: calc(100% - 33px);
  background-color: #161616;
}

.dashboardInfoScheduleEntry {
  padding: 2px 7px 7px 7px;
  /* border-bottom: 1px solid #dddddd; */
  cursor: pointer;
}
