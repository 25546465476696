.pageBackground{
  width: 100vw;
  height: 100vh;
  background-color: #111111;
}

.fullPage{
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.webpage{
  flex: 1;
  height: 100vh;
  overflow: auto;
}